<template>
    <div class="container">
        <header-with-line header="Work Life Balance" class="mb-5"/>
        <div class="row">
            <div class="col-lg-5 mb-5 pl-5 pr-5 mx-auto d-flex justify-content-center align-items-center">
                <img class="img-fluid shadow" src="../../assets/img/karriere/balancecollage.png" alt="Work Life Balance">
            </div>
            <div class="col-lg-7 text-center d-flex flex-column justify-content-around">
                <div>
                    <img class="img-fluid mb-3" src="../../assets/img/decorations/you_decide_when_and_how_much_you_work.svg" alt="Fleckdekoration">
                </div>
                <div class="position-relative mb-5">
                    <ul class="custom-list text-primary h3 text-left">
                        <li>
                            <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                            <span>Entspannte ausführliche Einarbeitungszeit</span>
                        </li>
                        <li>
                            <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                            <span>Flexible Arbeitszeitmodelle</span>
                        </li>
                        <li>
                            <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                            <span>30 Urlaubstage</span>
                        </li>
                        <li>
                            <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                            <span>Abwechslungsreicher Arbeitsbereiche</span>
                        </li>
                    </ul>
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-8 col-md-12">
                        <div>
                            <img class="img-fluid mb-3" src="../../assets/img/decorations/your_work_ your_time.svg" alt="Fleckdekoration">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HeaderWithLine from '../Ui/HeaderWithLine.vue';

export default {
    name: 'WorkLifeBalance',
    components: {
        HeaderWithLine
    },
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
    },
    mounted() {
    }
};
</script>
<style scoped>
.img-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-container img {
    max-width: 80%; /* für Handy auf 100% anpassen */
    height: auto;
    display: block;
}

.text-stroked {
    font-family: 'HP Simplified';
    color: white;
    font-size: 40px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    -webkit-text-stroke: 2px #08425e;
}

.custom-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.custom-list img {
    height: 40px;
    margin-right: 10px;
}

.custom-text-003 {
    font-family: 'HP Simplified';
    color: white;
    font-size: 40px;
    position: absolute;
    top: 43%;
    left: 52%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    -webkit-text-stroke: 2px #08425e;
}

@media screen and (max-width: 1399px) {
    .text-stroked {
        font-size: 2.8vw;
        -webkit-text-stroke: 0.15vw #08425e;
    }

    .custom-text-003 {
        font-size: 2.4vw;
        -webkit-text-stroke: 0.12vw #08425e;
    }
}

@media screen and (max-width: 991px) {
    .text-stroked {
        font-size: 4.2vw;
        -webkit-text-stroke: 0.25vw #08425e;
    }

    .custom-text-003 {
        font-size: 3.7vw;
        -webkit-text-stroke: 0.2vw #08425e;
    }
}

@media screen and (max-width: 575px) {
    .text-stroked {
        font-size: 5.5vw;
        -webkit-text-stroke: 0.3vw #08425e;
    }

    .custom-text-003 {
        font-size: 5vw;
        -webkit-text-stroke: 0.25vw #08425e;
    }
}
</style>
